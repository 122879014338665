import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import { SampleLibraryType, SampleTypeListViewModel } from '@/api/api';
import {
  sampleTypeListDispatcher,
  SampleTypeListInput,
  sampleTypeListNamespace
} from './sample-type-list.modules';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import ApiClientFactory from '@/api/apiClientFactory';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { BaseStatus } from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import SampleTypeFormModal from '@/components/sampleType/SampleTypeFormModal.vue';
@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    SampleTypeFormModal,
    BCardText,
    Pagination,
    BImg,
    BBadge
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: ''
      },
      dispatcher: sampleTypeListDispatcher,
      debouncedWatchers: [(listInput) => listInput.searchText]
    })
  ]
})
export default class SampleTypeList
  extends Vue
  implements ListVue<SampleTypeListViewModel, SampleTypeListInput> {
  @sampleTypeListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    SampleTypeListViewModel,
    SampleTypeListInput
  >[ListGetter.state];

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  SAMPLE_TYPE_STATUS_ENUM = BaseStatus;
  $refs!: {
    sampleTypeFormModal: any;
  };

  sampleTypeStatusOptions: DropdownOption[] = enumToDropdownOptions(BaseStatus);
  sampleTypeOptions: DropdownOption[] = enumToDropdownOptions(
    SampleLibraryType
  );

  displaySampleTypeStatus(value) {
    return this.$t(
      this.sampleTypeStatusOptions.find((x) => x.value === value)?.text ?? ''
    );
  }

  displaySampleType(value) {
    return (
      'Theo ' +
      this.$t(this.sampleTypeOptions.find((x) => x.value === value)?.text ?? '')
    );
  }

  mounted() {
    sampleTypeListDispatcher.load();
  }

  edit(id: number) {
    this.$refs.sampleTypeFormModal.openEditModal(id);
  }

  remove(id: number) {
    const client = new ApiClientFactory().sampleTypeClient();
    client.delete(id).then(() => {
      sampleTypeListDispatcher.load();
      // show toast
      this.$bvToast.toast('Xóa danh mục thành công', {
        title: 'Thư viện tem',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }

  headers = [
    {
      label: 'Tên',
      field: 'name',
      tdClass: 'align-content-center'
    },
    {
      label: 'Loại danh mục',
      field: 'type',
      tdClass: 'align-content-center'
    },
    {
      label: 'Hoạt động',
      field: 'status',
      width: '20%',
      tdClass: 'align-content-center'
    },
    {
      label: '',
      field: '_action',
      sortable: false,
      width: '1%',
      tdClass: 'text-center'
    }
  ];
}
