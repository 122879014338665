import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { ShapeStatus, BaseStatus, SampleLibraryType } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { sampleTypeListDispatcher } from '@/views/sampleType/sampleTypeList/sample-type-list.modules';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption
  }
})
export default class SampleTypeFormModal extends Vue {
  sampleTypeId: number | null = null;

  input = this.getDefaultInputValue();

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  sampleStatusOptions: DropdownOption[] = enumToDropdownOptions(BaseStatus);
  sampleTypeOptions: DropdownOption[] = enumToDropdownOptions(
    SampleLibraryType
  );

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };

  sampleTypeEntityUpdated(id): void {
    if (id) {
      const client = new ApiClientFactory().sampleTypeClient();

      client
        .get(id)
        .then((result) => {
          if (result) {
            this.sampleTypeId = result.id;
            this.input = {
              name: result.name as string,
              status: result.status as BaseStatus,
              type: result.type
            };

            return;
          }

          this.$bvToast.toast('Tải danh mục thất bại', {
            title: 'Danh mục thư viện tem',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        })
        .catch((err) => {
          this.$bvToast.toast('Tải danh mục thất bại', {
            title: 'Danh mục thư viện tem',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        });
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  openCreateModal(): void {
    this.sampleTypeId = null;
    this.input = this.getDefaultInputValue();
    this.$refs.modal.show();
  }

  async openEditModal(id: number): Promise<void> {
    this.sampleTypeEntityUpdated(id);
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      name: '',
      status: BaseStatus.Active,
      type: SampleLibraryType.Shape
    };
  }

  add(): void {
    const client = new ApiClientFactory().sampleTypeClient();

    client
      .create(this.input.name, this.input.status, this.input.type)
      .then(() => {
        sampleTypeListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Thêm mới thành công', {
          title: 'Danh mục thư viện tem',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      })
      .catch((err) => {
        this.$bvToast.toast('Thêm danh mục thất bại', {
          title: 'Danh mục thư viện tem',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });
  }

  edit() {
    const client = new ApiClientFactory().sampleTypeClient();

    client
      .edit(
        this.sampleTypeId ?? 0,
        this.input.name,
        this.input.status,
        this.input.type
      )
      .then(() => {
        sampleTypeListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Chỉnh sửa thành công', {
          title: 'Danh mục thư viện tem',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      })
      .catch((err) => {
        this.$bvToast.toast('Chỉnh sửa danh mục thất bại', {
          title: 'Danh mục thư viện tem',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });
  }

  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.sampleTypeId) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }
}
