var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{on:{"click":_vm.openCreateModal}},[_vm._t("default")],2),_c('b-modal',{ref:"modal",attrs:{"id":"modal","cancel-variant":"outline-secondary","ok-title":_vm.$t('Save'),"ok-only":"","centered":"","title":_vm.sampleTypeId ? 'Chỉnh sửa' : 'Thêm mới',"no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'name',"text":'Tên',"required":true}}),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'status',"text":'Hoạt động',"required":true}}),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-select',{attrs:{"id":"status","state":errors.length > 0 ? false : null},model:{value:(_vm.input.status),callback:function ($$v) {_vm.$set(_vm.input, "status", $$v)},expression:"input.status"}},_vm._l((_vm.sampleStatusOptions),function(item){return _c('b-form-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),1),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'status',"text":'Loại danh mục',"required":true}}),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-select',{attrs:{"id":"status","state":errors.length > 0 ? false : null},model:{value:(_vm.input.type),callback:function ($$v) {_vm.$set(_vm.input, "type", $$v)},expression:"input.type"}},_vm._l((_vm.sampleTypeOptions),function(item){return _c('b-form-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s('Theo ' + _vm.$t(item.text))+" ")])}),1),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }